// In case of multiple sites
// import { Site } from '@constants/site';

export function getWebsiteApiUrl (): string {
  return getEnv('REACT_APP_WEBSITE_API_URL')
}
export function getWebsiteOrigin (): string {
  return getEnv('REACT_APP_WEBSITE_URL')
}
export function getWebsiteRestApiUrl (): string {
  return getEnv('REACT_APP_WEBSITE_REST_API_URL')
}

export function getImageUrl (): string {
  return 'https://z-art-img.businessartservice.nl/'
}
export function getMSClientId (): string {
  return getEnv('REACT_APP_MS_CLIENT_ID')
}
export function getMSAuthority (): string {
  return getEnv('REACT_APP_MS_AUTHORITY')
}

function getEnv (key: string): string {
  const value = process.env[key]

  if (value === undefined) {
    throw new Error(`Required environment variable ${key} not set`)
  }

  return value
}
