import React from 'react'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import MoreVert from '@mui/icons-material/MoreVert'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography
} from '@mui/material'
import { Maybe, ProposalLine, SuggestedCustomerArtLocation } from '@root/generated/graphql-request'
import { FloorIcon } from '../Icons/Component'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMsal } from '@azure/msal-react'
import { patchProposalLine } from '@root/services/microsoftAuthService'
import { DeleteProposalLineDialog } from '../DeleteProposalLineDialog/Component'
import { useAppContext } from '@root/global/context'
import { errorToastHelper } from '@root/misc/helpers'

interface CustomerArtLocationDialogProps {
  children: React.ReactNode
  proposalLine: ProposalLine
  suggestedCustomerArtLocations: string[]
  onProposalLineChange: () => void
}

interface Props {
  proposalLine: ProposalLine
  suggestedCustomerArtLocations: string[]
  addNoteCallback: () => void
  onProposalLineChange: () => void
}

const CustomerArtLocationDialog = (props: CustomerArtLocationDialogProps) => {
  const [open, setOpen] = React.useState(false)
  const [customerArtLocation, setCustomerArtLocation] = React.useState<
  string | null
  >(props.proposalLine.customerArtLocation ?? null)
  const { instance } = useMsal()
  const [customerArtLocationError, setCustomerArtLocationError] =
    React.useState<boolean>(false)
  const { errors, setErrors } = useAppContext()

  const handleCustomerArtLocationChange = (
    value: string | Maybe<SuggestedCustomerArtLocation>
  ): void => {
    setCustomerArtLocationError(false)
    if (typeof value === 'string') {
      setCustomerArtLocation(value)
    } else {
      setCustomerArtLocation(value?.customerArtLocation ?? '')
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    if (
      customerArtLocation?.match(/^([^-]*)-([^-]*)-([^-]*)-([^-]*)$/) === null
    ) {
      setCustomerArtLocationError(true)
      return
    }
    if (
      customerArtLocation &&
      props.proposalLine.customerArtLocation !== customerArtLocation
    ) {
      patchProposalLine(
        props.proposalLine.id,
        { customerArtLocation },
        instance
      )
        .then(() => {
          props.onProposalLineChange()
          closeDialog()
        })
        .catch((_err) => {
          setErrors(
            errorToastHelper(
              errors,
              'Het wijzigen van de ruimte is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
            )
          )
        })
    }
  }

  return (
    <>
      <div onClick={openDialog}>{props.children}</div>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>Wijzig ruimte</DialogTitle>
        <DialogContent>
          <Box
            width={488}
            display='flex'
            flexDirection='column'
            gap={2}
            marginTop={1}
          >
            <Autocomplete
              freeSolo
              popupIcon={<ExpandMoreIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Ruimte'
                  placeholder='A - 01 - 001 - Naam ruimte'
                  required
                  value={customerArtLocation}
                  error={customerArtLocationError}
                  onChange={(ev) => {
                    handleCustomerArtLocationChange(ev.target.value)
                  }}
                  helperText='Vul in het formaat in: A - 01 - 001 - Naam ruimte'
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
              id='customer-art-location-select'
              options={props.suggestedCustomerArtLocations}
              value={customerArtLocation}
              onChange={(ev, newVal) => setCustomerArtLocation(newVal ?? '')}
              getOptionLabel={(sa) => sa}
              noOptionsText='Geen locaties bekend'
              sx={{
                '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                  color: 'var(--color-brand-quarternary-300)',
                  width: 24,
                  height: 24
                }
              }}
            />
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button variant='contained' type='submit'>
              Verplaatsen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export const ProposalLineQuickMenu = ({
  proposalLine,
  addNoteCallback,
  onProposalLineChange,
  suggestedCustomerArtLocations
}: Props) => {
  const buttonId = 'proposal-edit-button-' + proposalLine.id
  const anchorId = 'proposal-edit-menu-' + proposalLine.id
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id={buttonId}
        aria-controls={open ? anchorId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert sx={{ fill: 'rgba(148, 148, 148, 1)' }} />
      </IconButton>
      <Menu open={open} id={anchorId} onClose={handleClose} anchorEl={anchorEl}>
        <MenuList>
          <MenuItem
            sx={{
              height: '48px',
              gap: '24px',
              '&:hover': {
                backgroundColor: 'rgba(245, 247, 251, 1)'
              }
            }}
            onClick={() => {
              addNoteCallback()
              handleClose()
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '24px'
              }}
            >
              <MessageOutlinedIcon width='24px' height='24px' />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body1'>Voeg opmerking toe</Typography>
            </ListItemText>
          </MenuItem>
          <CustomerArtLocationDialog
            proposalLine={proposalLine}
            suggestedCustomerArtLocations={suggestedCustomerArtLocations}
            onProposalLineChange={onProposalLineChange}
          >
            <MenuItem
              sx={{
                height: '48px',
                gap: '24px',
                '&:hover': {
                  backgroundColor: 'rgba(245, 247, 251, 1)'
                }
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '24px'
                }}
              >
                <FloorIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='body1'>Wijzig ruimte</Typography>
              </ListItemText>
            </MenuItem>
          </CustomerArtLocationDialog>
          <DeleteProposalLineDialog
            proposalLine={proposalLine}
            onProposalLineChange={onProposalLineChange}
          >
            <MenuItem
              sx={{
                color: 'rgba(183, 21, 56, 1)',
                height: '48px',
                gap: '24px',
                '&:hover': {
                  backgroundColor: 'rgba(245, 247, 251, 1)'
                }
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '24px'
                }}
              >
                <DeleteForeverRoundedIcon
                  sx={{ color: 'rgba(183, 21, 56, 1)' }}
                  width='24px'
                  height='24px'
                />
              </ListItemIcon>
              <ListItemText>Verwijder uit voorstel</ListItemText>
            </MenuItem>
          </DeleteProposalLineDialog>
        </MenuList>
      </Menu>
    </>
  )
}
