import React from 'react'
import { isClient } from './is-environment'

interface Size {
  width: number | undefined
  height: number | undefined
  isMobile: boolean
  isMobileLargeAndAbove: boolean
  isTabletAndAbove: boolean
  isDesktopAndAbove: boolean
  isLargeDesktopAndAbove: boolean
}

interface BreakpointOverrides {
  isLargeDesktopBreakpoint: number | undefined
}

export default function useWindowSize (props?: BreakpointOverrides): Size {
  const [windowSize, setWindowSize] = React.useState<Size>({
    width: undefined,
    height: undefined,
    isMobile: false,
    isMobileLargeAndAbove: false,
    isTabletAndAbove: false,
    isDesktopAndAbove: false,
    isLargeDesktopAndAbove: false
  })

  React.useEffect(() => {
    function handleResize (): void {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < 576,
        isMobileLargeAndAbove: window.innerWidth >= 576,
        isTabletAndAbove: window.innerWidth >= 768,
        isDesktopAndAbove: window.innerWidth >= 992,
        isLargeDesktopAndAbove: window.innerWidth >= (props?.isLargeDesktopBreakpoint ?? 1280)
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isClient()) {
    return windowSize
  } else {
    return {
      width: undefined, height: undefined, isTabletAndAbove: false, isDesktopAndAbove: false, isMobileLargeAndAbove: false, isMobile: false, isLargeDesktopAndAbove: false
    }
  }
}
