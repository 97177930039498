import { Alert, Box, Snackbar } from '@mui/material'
import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'

interface Props {
  children: string
  duration: number
  index: number
  onClose: () => void
}

export const ErrorToast = (props: Props): JSX.Element => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      props.onClose()
      return () => clearTimeout(timeout)
    }, props.duration)
  }, [])
  const marginTop = props.index * 56

  return (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Box
        sx={{
          marginTop: marginTop + 'px'
        }}
      >
        <Alert
          sx={{ backgroundColor: '#FBDFE5', color: '#490817' }}
          severity='error'
          icon={<ErrorIcon />}
        >
          {props.children}
        </Alert>
      </Box>
    </Snackbar>
  )
}
