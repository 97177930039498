import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { Grid } from '@root/components/atoms/Grid/Component'
import {
  BuildingIcon,
  ImagePlusIcon,
  PencilIcon,
  PersonIcon
} from '@root/components/atoms/Icons/Component'
import { ProposalQuickMenu } from '@root/components/atoms/ProposalQuickMenu/Component'
import { SwirlComponent } from '@root/components/atoms/Swirl/Component'
import { Proposal, UserProfile } from '@root/generated/graphql-request'
import { formatDateTime } from '@root/misc/helpers'
import React from 'react'
import { redirect, useNavigate } from 'react-router-dom'
import style from './style.module.css'

interface ProposalsListHeaderProps {
  userProfile: UserProfile
}

interface ProposalOverviewHeaderProps {
  proposal: Proposal
}

interface ProposalsListHeaderState {
  kind: 'proposals'
  props: ProposalsListHeaderProps
}

interface ProposalOverviewHeaderState {
  kind: 'proposal-overview'
  props: ProposalOverviewHeaderProps
}

export type HeaderState =
  | ProposalsListHeaderState
  | ProposalOverviewHeaderState

interface HeaderProps {
  /** Determines what content to show in the header. */
  state: HeaderState
}

export const ProposalOverviewHeader = (props: ProposalOverviewHeaderProps) => {
  const [proposal, setProposal] = React.useState<Proposal>(props.proposal)
  const navigate = useNavigate()
  return (
    <>
      <div>
        <div className={style['go-back-container']} onClick={() => navigate('/portaal')}>
          <ArrowBackIcon />
          <Typography variant='button'>Terug</Typography>
        </div>
        <Typography
          variant='h4'
          sx={{
            color: 'var(--color-brand-primary-400)',
            fontWeight: 400
          }}
        >
          Voorstel
        </Typography>
        <div className={style['proposal-title-container']}>
          <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>
            {proposal.title}
          </Typography>
          <ProposalQuickMenu
            proposal={proposal}
            iconColor='rgba(161, 187, 232, 1)'
            onEditSuccess={(p) => {
              setProposal(p)
            }}
            onDelete={() => redirect('/portaal')}
          />
        </div>
        <div className={style['header-metadata']}>
          <div className={style['metadata-item']}>
            <PersonIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-100)' }}
            >
              {proposal.account.ownerFirstName} {proposal.account.ownerLastName}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <BuildingIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-100)' }}
            >
              {proposal.customerSite.name}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <ImagePlusIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-100)' }}
            >
              {formatDateTime(proposal.createdAt)}
            </Typography>
          </div>
          <div className={style['metadata-item']}>
            <PencilIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-100)' }}
            >
              {formatDateTime(proposal.updatedAt)}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        {/* TODO: for user story 2b
          <Button
          variant='contained'
          color='info'
          className={style['art-consultant']}
          disabled
          startIcon={
            <FormatListBulletedIcon
              sx={{ color: 'var(--color-brand-quarternary-300)' }}
            />
          }
        >
          Bekijk voorstel
        </Button> */}
      </div>
    </>
  )
}

export const ProposalsListHeader = (props: ProposalsListHeaderProps) => {
  return (
    <div className={style['art-consultant-container']}>
      <Avatar
        src={props.userProfile.profileImage!}
        sx={{ height: 72, width: 72 }}
      />
      <div>
        <Typography
          variant='h4'
          sx={{
            color: 'var(--color-brand-primary-400)',
            fontWeight: 400
          }}
        >
          Welkom,
        </Typography>
        <Typography variant='h1' sx={{ color: 'var(--color-neutral-100)' }}>
          {props.userProfile.fullName}
        </Typography>
      </div>
    </div>
  )
}

export const ConsultantHeader = ({ state }: HeaderProps) => {
  const HeaderContent = () => {
    switch (state.kind) {
      case 'proposal-overview':
        return <ProposalOverviewHeader proposal={state.props.proposal} />
      case 'proposals':
        return <ProposalsListHeader userProfile={state.props.userProfile} />
    }
  }

  return (
    <header className={style.header}>
      <div className={style.swirl}>
        <SwirlComponent />
      </div>
      <Grid style={{ rowGap: '24px' }}>
        <div className={style.wrapper}>
          <HeaderContent />
        </div>
      </Grid>
    </header>
  )
}
