import { DisplayableAvailability } from '@root/generated/graphql-request'

export const AvailabilityFlag = (props: {
  availability: DisplayableAvailability
}) => {
  switch (props.availability) {
    case DisplayableAvailability.Unavailable:
      return (
        <>
          <span> · </span>
          <span style={{ color: 'rgba(201, 23, 62, 1)' }}>
            Niet beschikbaar
          </span>
        </>
      )
    case DisplayableAvailability.OutOfCollection:
      return (
        <>
          <span> · </span>
          <span style={{ color: 'rgba(201, 23, 62, 1)' }}>Uit collectie</span>
        </>
      )

    case DisplayableAvailability.Available:
      return <span />
  }
}
