import React from 'react'
import { Typography } from '@mui/material'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { Displayable, Proposal } from '@root/generated/graphql-request'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import TollIcon from '@mui/icons-material/Toll'

import { artSize, formatDecimal, LikedState } from '@root/misc/helpers'
import style from './style.module.css'
import { HeartFilled, Painting } from '@root/components/atoms/Icons/Component'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { ProposalLineDialog } from '@root/components/atoms/ProposalLineDialog/Component'
import { AvailabilityFlag } from '@root/components/atoms/DisplayAvailabilityFlag/Component'
import DisplayableDetailView from '../DisplayableDetailView/Component'
import { DeleteProposalLineDialog } from '@root/components/atoms/DeleteProposalLineDialog/Component'

interface Props {
  displayable: Displayable
  proposal: Proposal
  /** Handler for 'liking', a.k.a. adding a `Displayable` to a `ProposalLine` through the grid. */
  onChange: () => void
  likedState: LikedState
}

export const DisplayableCard = (props: Props): JSX.Element => {
  const [openDetailView, setOpenDetailView] = React.useState<boolean>(false)
  return (
    <div className={style.card}>
      <DisplayableDetailView
        displayable={props.displayable}
        proposal={props.proposal}
        onChange={props.onChange}
        likedState={props.likedState}
        open={openDetailView}
        onClose={() => setOpenDetailView(false)}
      />
      <div className={style['image-wrapper']}>
        {props.likedState.kind === 'isLiked' &&
        'proposalLine' in props.likedState
          ? (
            <DeleteProposalLineDialog
              proposalLine={props.likedState.proposalLine}
              onProposalLineChange={props.onChange}
            >
              <HeartFilled />
            </DeleteProposalLineDialog>
            )
          : (
            <ProposalLineDialog
              proposal={props.proposal}
              displayableId={props.displayable.id}
              onChange={props.onChange}
            >
              <FavoriteBorderIcon />
            </ProposalLineDialog>
            )}
        <div
          className={style['displayable-image']}
          onClick={() => setOpenDetailView(true)}
        >
          <ArtImage
            src={props.displayable.mainImagePath}
            alt={props.displayable.title}
            size='medium'
          />
        </div>
      </div>

      <div className={style.metas}>
        <div>
          <Typography variant='body2'>
            {props.displayable.referenceNumber}

            <AvailabilityFlag availability={props.displayable.availability} />
          </Typography>
        </div>
        <Typography variant='h2'>
          {props.displayable.mainArtist.artist}
        </Typography>
        <div className={style.meta}>
          <Painting width='24px' height='24px' />
          <Typography
            variant='body1'
            sx={{ color: 'var(--color-neutral-600)' }}
          >
            {props.displayable.title}
          </Typography>
        </div>

        <div className={style.meta}>
          <PhotoSizeSelectLargeIcon height='16px' width='16px' />
          <Typography
            variant='body1'
            sx={{ color: 'var(--color-neutral-600)' }}
          >
            {artSize(props.displayable)}
          </Typography>
        </div>

        <div className={style.meta}>
          <TollIcon height='16px' width='16px' />
          <Typography
            variant='body1'
            sx={{ color: 'var(--color-neutral-600)' }}
          >
            {formatDecimal(props.displayable.numberOfUnits)} eenheden
          </Typography>
        </div>
      </div>
    </div>
  )
}
