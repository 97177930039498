import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

interface NoPermission {
  kind: 'no-permission'
}

interface GenericError {
  kind: 'generic'
  errorCode: string
}

interface NoError {
  kind: 'no-error'
}
export type ErrorState = NoError | NoPermission | GenericError

interface Props {
  /** The function that starts the MSAL login process again. */
  retryLogin: () => void
  /** Callback that will be executed whenever the dialog gets closed. */
  onClose: () => void
  /** Shows the dialog whenever this is set to anything else besides 'no-error'.  */
  state: ErrorState
}

export const LoginFailedDialog = (props: Props) => {
  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    props.retryLogin()
    props.onClose()
    closeDialog()
  }

  const closeDialog = () => {
    props.onClose()
  }

  return (
    <>
      <Dialog
        open={props.state.kind !== 'no-error'}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>
          {props.state.kind === 'no-permission' &&
            'Benodigde toestemmingen zijn niet gegeven'}
          {props.state.kind === 'generic' && 'Inloggen mislukt'}
        </DialogTitle>
        <DialogContent>
          <Box
            width={488}
            display='flex'
            flexDirection='column'
            gap={2}
            marginTop={1}
          >
            <DialogContentText>
              {props.state.kind === 'no-permission' &&
                `Tijdens het inloggen via Office 365 dienen data-deling permissies
              goedgekeurd te worden. Probeer opnieuw in te loggen en geef de
              juiste permissies.`}

              {props.state.kind === 'generic' &&
                `Er ging iets mis bij het inloggen, probeer het opnieuw of neem contact op met de beheerder. Foutcode: ${props.state.errorCode}`}
            </DialogContentText>
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Sluiten
            </Button>
            <Button variant='contained' type='submit' onClick={handleSubmit}>
              Opnieuw inloggen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
