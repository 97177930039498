import React from 'react'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import TollIcon from '@mui/icons-material/Toll'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { AvailabilityFlag } from '@root/components/atoms/DisplayAvailabilityFlag/Component'
import { Painting } from '@root/components/atoms/Icons/Component'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { ProposalLineQuickMenu } from '@root/components/atoms/ProposalLineQuickMenu/Component'
import { Maybe, Proposal, ProposalLine } from '@root/generated/graphql-request'
import {
  artSize,
  errorToastHelper,
  filterMaybes,
  formatDecimal,
  groupBy,
  isProposalLine
} from '@root/misc/helpers'
import { sum } from 'lodash'
import style from './style.module.css'
import { IPublicClientApplication } from '@azure/msal-browser'
import {
  patchProposal,
  patchProposalLine
} from '@root/services/microsoftAuthService'
import { useMsal } from '@azure/msal-react'
import { Loader } from '@root/components/atoms/Loader/Component'
import DisplayableDetailView from '../DisplayableDetailView/Component'
import { useAppContext } from '@root/global/context'

interface ProposalInfoProps {
  proposal: Proposal
  proposalLines: Maybe<Array<Maybe<ProposalLine>>>
  instance: IPublicClientApplication
  onProposalLinesChange: () => void
  isLoadingProposal: boolean
}

interface ProposalLineCardProps {
  proposalLine: ProposalLine
  proposal: Proposal
  suggestedCustomerArtLocations: string[]
  onProposalLineChange: () => void
  onSelectProposalLine: () => void
}

interface ProposalLineNoteProps {
  proposalLine: ProposalLine
  isCreatingNote: boolean
  setIsCreatingNote: React.Dispatch<React.SetStateAction<boolean>>
}

interface DeleteProposalLineNoteDialogProps {
  children: React.ReactNode
  onProposalLineNoteDelete: () => void
  proposalLine: ProposalLine
}

const DeleteProposalLineNoteDialog = (
  props: DeleteProposalLineNoteDialogProps
) => {
  const [open, setOpen] = React.useState(false)
  const { instance } = useMsal()
  const { errors, setErrors } = useAppContext()

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSubmit = (ev: any) => {
    ev.preventDefault()
    patchProposalLine(props.proposalLine.id, { note: null }, instance)
      .then((_resp) => {
        props.onProposalLineNoteDelete()
        closeDialog()
      })
      .catch((_err) => {
        setErrors(
          errorToastHelper(
            errors,
            'Het verwijderen van de opmerking is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
          )
        )
      })
  }
  return (
    <>
      <div onClick={openDialog} style={{ height: 'fit-content' }}>
        {props.children}
      </div>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>Opmerking verwijderen</DialogTitle>
        <DialogContent>
          <Box width={488} display='flex' flexDirection='column' gap={2}>
            <DialogContentText>
              Weet je zeker dat je deze opmerking wil verwijderen? Dit kan niet
              ongedaan gemaakt worden.
            </DialogContentText>
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button
              variant='contained'
              color='error'
              type='submit'
              sx={{
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.08)'
                }
              }}
            >
              Verwijderen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

const ProposalLineNote = (props: ProposalLineNoteProps) => {
  const [note, setNote] = React.useState<string | undefined>(
    props.proposalLine.note ?? undefined
  )
  const { instance } = useMsal()
  const { errors, setErrors } = useAppContext()

  React.useEffect(() => {
    const controller = new AbortController()

    const signal = controller.signal

    const debounceFn = setTimeout(() => {
      if (note !== undefined && props.proposalLine.note !== note) {
        patchProposalLine(props.proposalLine.id, { note }, instance, {
          signal
        }).catch((error) => {
          if (error.name === 'AbortError') {
            // We aborted the request with `controller` here. No need to show a toast.
          } else {
            setErrors(
              errorToastHelper(
                errors,
                'Het aanpassen van de opmerking is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
              )
            )
          }
        })
      }
    }, 500)

    return () => {
      controller.abort()
      clearTimeout(debounceFn)
    }
  }, [note])

  return (note !== undefined) || props.isCreatingNote
    ? (
      <div className={style['proposal-line-note-container']}>
        <TextField
          fullWidth
          multiline
          minRows={1}
          label='Opmerking'
          variant='outlined'
          value={note}
          onChange={(ev) => setNote(ev.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              background: 'var(--color-neutral-100)'
            }
          }}
        />

        <DeleteProposalLineNoteDialog
          proposalLine={props.proposalLine}
          onProposalLineNoteDelete={() => {
            setNote(undefined)
            props.setIsCreatingNote(false)
          }}
        >
          <DeleteForeverRoundedIcon
            sx={{ color: 'rgba(183, 21, 56, 1)', cursor: 'pointer' }}
            width='24px'
            height='24px'
          />
        </DeleteProposalLineNoteDialog>
      </div>
      )
    : (
      <></>
      )
}

const ProposalLineCard = ({
  proposalLine,
  suggestedCustomerArtLocations,
  onProposalLineChange,
  onSelectProposalLine
}: ProposalLineCardProps) => {
  const [isCreatingNote, setIsCreatingNote] = React.useState<boolean>(false)

  return (
    <div className={style['proposal-line-card']}>
      <div className={style['proposal-line-content']}>
        <div
          className={style['proposal-line-cover']}
          onClick={onSelectProposalLine}
        >
          <ArtImage
            src={proposalLine.displayable.mainImagePath}
            alt={proposalLine.displayable.title}
            size='medium'
          />
        </div>
        <div className={style.metas}>
          <div>
            <Typography variant='body2'>
              {proposalLine.displayable.referenceNumber}

              <AvailabilityFlag
                availability={proposalLine.displayable.availability}
              />
            </Typography>
          </div>
          <Typography variant='h2'>
            {proposalLine.displayable.mainArtist.artist}
          </Typography>
          <div className={style.meta}>
            <Painting width='24px' height='24px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-600)' }}
            >
              {proposalLine.displayable.title}
            </Typography>
          </div>

          <div className={style.meta}>
            <PhotoSizeSelectLargeIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-600)' }}
            >
              {artSize(proposalLine.displayable)}
            </Typography>
          </div>

          <div className={style.meta}>
            <TollIcon height='16px' width='16px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-600)' }}
            >
              {formatDecimal(proposalLine.displayable.numberOfUnits)} eenheden
            </Typography>
          </div>
        </div>
        <div className={style['proposal-line-quick-menu']}>
          <ProposalLineQuickMenu
            proposalLine={proposalLine}
            addNoteCallback={() => setIsCreatingNote(true)}
            suggestedCustomerArtLocations={suggestedCustomerArtLocations}
            onProposalLineChange={onProposalLineChange}
          />
        </div>
      </div>
      <ProposalLineNote
        proposalLine={proposalLine}
        isCreatingNote={isCreatingNote}
        setIsCreatingNote={setIsCreatingNote}
      />
    </div>
  )
}

const customerArtLocation = (pl: ProposalLine): string =>
  pl.customerArtLocation ?? 'Geen ruimte'

export const ProposalInfo = ({
  proposal,
  proposalLines,
  onProposalLinesChange,
  instance,
  isLoadingProposal
}: ProposalInfoProps) => {
  const [introduction, setIntroduction] = React.useState<string>(
    proposal.introduction ?? ''
  )
  const [saved, setSaved] = React.useState<boolean>(false)
  const [selectedProposalLine, setSelectedProposalLine] =
    React.useState<ProposalLine | null>(null)
  const { errors, setErrors } = useAppContext()
  const [openDetailView, setOpenDetailView] = React.useState(false)

  React.useEffect(() => {
    const controller = new AbortController()

    const signal = controller.signal

    setSaved(false)
    const debounceFn = setTimeout(() => {
      if (proposal.introduction !== introduction) {
        patchProposal(proposal.id, { introduction }, instance, { signal })
          .then(() => {
            setSaved(true)
            // setTimeout((_) => setSaved(false), 3000)
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              // We aborted the request with `controller` here. No need to show a toast.
            } else {
              setErrors(
                errorToastHelper(
                  errors,
                  'Het aanpassen van de opmerking is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
                )
              )
            }
          })
      }
    }, 500)

    return () => {
      controller.abort()
      clearTimeout(debounceFn)
    }
  }, [introduction])

  return (
    <div className={style['proposal-info-container']}>
      <div className={style.title}>
        <Typography variant='h2'>Voorstel</Typography>
      </div>
      <TextField
        fullWidth
        multiline
        minRows={2}
        label='Introductie'
        variant='outlined'
        value={introduction}
        onChange={(ev) => setIntroduction(ev.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            background: 'var(--color-neutral-100)'
          }
        }}
      />

      <Box className={style['proposal-metas-container']}>
        <div className={style['proposal-metas']}>
          <div className={style['proposal-meta']}>
            <Painting width='24px' height='24px' />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-600)' }}
            >
              {proposal.proposalLines?.length} kunstwerken
            </Typography>
          </div>
          <div className={style['proposal-meta']}>
            <TollIcon />
            <Typography
              variant='body1'
              sx={{ color: 'var(--color-neutral-600)' }}
            >
              {sum(
                proposal.proposalLines!.map(
                  (pl) => pl!.displayable.numberOfUnits
                )
              )}{' '}
              eenheden
            </Typography>
          </div>
        </div>
        {saved
          ? (
            <div className={style['is-saved-container']}>
              <CheckCircleOutlineIcon color='success' />
              <Typography
                variant='body1'
                sx={{ color: 'var(--color-neutral-600)' }}
              >
                Opgeslagen
              </Typography>
            </div>
            )
          : (
            <></>
            )}
      </Box>

      <Divider
        sx={{
          color: 'rgba(0, 0, 0, 0.08)',
          marginTop: '24px',
          marginBottom: '40px'
        }}
      />

      {isLoadingProposal
        ? (
          <Loader />
          )
        : (
          <div>
            {selectedProposalLine != null && (
              <DisplayableDetailView
                displayable={selectedProposalLine?.displayable}
                onChange={() => onProposalLinesChange()}
                open={openDetailView}
                onClose={() => setOpenDetailView(false)}
                proposal={proposal}
                likedState={isProposalLine(
                  selectedProposalLine.displayable,
                  proposalLines
                )}
              />
            )}
            {groupBy(filterMaybes(proposalLines), customerArtLocation).length ===
          0
              ? (
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography variant='h3' sx={{ marginBlockEnd: '4px' }}>
                    Geen kunstwerken toevoegd
                  </Typography>
                  <Typography variant='body1'>
                    Klik op het hartje op de foto’s van de kunstwerken of op de knop
                    in de detailweergaven om een kunstwerk toe te voegen aan dit
                    voorstel.
                  </Typography>
                </Box>
                )
              : (
                  groupBy(filterMaybes(proposalLines), customerArtLocation).map(
                    (plg) => (
                      <div className={style['proposal-lines-section']} key={plg.key}>
                        <Typography variant='h3' marginBlockEnd={2}>
                          {plg.key}
                        </Typography>
                        {plg.value.map((pl, idx) => {
                          const suggestedCustomerArtLocations =
                      proposal.suggestedCustomerArtLocations != null
                        ? proposal.suggestedCustomerArtLocations.map(
                          (sa) => sa!.customerArtLocation
                        )
                        : []

                          return (
                            <ProposalLineCard
                              proposalLine={pl}
                              key={pl.id}
                              suggestedCustomerArtLocations={
                          suggestedCustomerArtLocations
                        }
                              onProposalLineChange={onProposalLinesChange}
                              proposal={proposal}
                              onSelectProposalLine={() => {
                                setOpenDetailView(true)
                                setSelectedProposalLine(pl)
                              }}
                            />
                          )
                        })}
                      </div>
                    )
                  )
                )}
          </div>
          )}
    </div>
  )
}
