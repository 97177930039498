interface DynamicFilter {
  field: string
  type: 'dynamic'
}

interface StaticFilterSlider {
  min: number
  max: number
  steps: number
  type: 'slider'
}

interface StaticFilterOrientation {
  options: string[]
  type: 'orientation'
}

/** A type to represent the 'Eigendomschap' filter.
    This one is almost the same as DynamicFilter, except for
    the fact that ownership input is a flat array instead of a nested structure.
  */
interface StaticFilterOwnership {
  type: 'ownership'
}

interface StaticFilterArtists {
  type: 'searchArtists'
}

/** For filters that only can be one of: null | true | false */
interface StaticFilterBoolean {
  // TODO: make this its own proper type.
  options: Array<{ label: string, value: null | boolean }>
  /* Decides what option will be pre-selected. */
  defaultValue: boolean | null
  type: 'boolean'
}

interface FilterData {
  key: string
  title: string
  defaultOpen: boolean
  data:
  | DynamicFilter
  | StaticFilterSlider
  | StaticFilterOrientation
  | StaticFilterOwnership
  | StaticFilterArtists
  | StaticFilterBoolean
}

export const displayableFilters: FilterData[] = [
  {
    key: 'artObjectCategories',
    title: 'Categorie',
    data: {
      type: 'dynamic',
      field: 'category'
    },
    defaultOpen: true
  },
  {
    key: 'numberOfUnits',
    title: 'Aantal eenheden',
    data: {
      type: 'slider',
      min: 0,
      max: 20,
      steps: 0.5
    },
    defaultOpen: true
  },
  {
    key: 'mainThemeCategories',
    title: 'Themacategorie',
    data: {
      type: 'dynamic',
      field: 'category'
    },
    defaultOpen: true
  },
  {
    key: 'mainStyles',
    title: 'Hoofdstijl',
    data: {
      type: 'dynamic',
      field: 'style'
    },
    defaultOpen: true
  },
  {
    key: 'mainColors',
    title: 'Hoofdkleur',
    data: {
      type: 'dynamic',
      field: 'color'
    },
    defaultOpen: true
  },
  {
    key: 'colorFamilies',
    title: 'Kleurfamilie',
    data: {
      type: 'dynamic',
      field: 'colorFamily'
    },
    defaultOpen: true
  },
  {
    key: 'mainArtists',
    title: 'Kunstenaar',
    data: {
      type: 'searchArtists'
    },
    defaultOpen: true
  },
  {
    key: 'height',
    title: 'Hoogte incl. lijst (cm)',
    data: {
      type: 'slider',
      min: 0,
      max: 500,
      steps: 5
    },
    defaultOpen: true
  },
  {
    key: 'width',
    title: 'Breedte incl. lijst (cm)',
    data: {
      type: 'slider',
      min: 0,
      max: 500,
      steps: 5
    },
    defaultOpen: true
  },
  {
    key: 'orientation',
    title: 'Oriëntatie',
    data: {
      type: 'orientation',
      options: ['Driedimensionaal', 'Portret', 'Landschap', 'Vierkant']
    },
    defaultOpen: true
  },
  {
    key: 'hasImage',
    title: 'Afbeelding',
    data: {
      type: 'boolean',
      options: [
        { label: 'Alles', value: null },
        { label: 'Beschikbaar', value: true },
        { label: 'Niet beschikbaar', value: false }
      ],
      defaultValue: true
    },
    defaultOpen: true
  },
  {
    key: 'hideOnWebsiteBtb',
    title: 'Zichtbaarheid B2B website',
    data: {
      type: 'boolean',
      options: [
        { label: 'Alles', value: null },
        { label: 'Zichtbaar', value: false },
        { label: 'Verborgen', value: true }
      ],
      defaultValue: false
    },
    defaultOpen: true
  },
  {
    key: 'pictureHeight',
    title: 'Hoogte beeld (cm)',
    data: {
      type: 'slider',
      min: 0,
      max: 500,
      steps: 5
    },
    defaultOpen: false
  },
  {
    key: 'pictureWidth',
    title: 'Breedte beeld (cm)',
    data: {
      type: 'slider',
      min: 0,
      max: 500,
      steps: 5
    },
    defaultOpen: false
  },
  {
    key: 'depth',
    title: 'Diepte incl. lijst (cm)',
    data: {
      type: 'slider',
      min: 0,
      max: 300,
      steps: 5
    },
    defaultOpen: false
  },
  {
    key: 'rentalValue',
    title: 'Verhuurwaarde',
    data: {
      type: 'slider',
      min: 0,
      max: 20_000,
      steps: 200
    },
    defaultOpen: false
  },
  {
    key: 'ownerships',
    title: 'Eigendomschap',
    data: {
      type: 'ownership'
    },
    defaultOpen: false
  },
  {
    key: 'objectTypes',
    title: 'Objecttype',
    data: {
      type: 'dynamic',
      field: 'objectType'
    },
    defaultOpen: false
  },
  {
    key: 'subColors',
    title: 'Subkleuren',
    data: {
      type: 'dynamic',
      field: 'color'
    },
    defaultOpen: false
  },
  {
    key: 'subStyles',
    title: 'Substijlen',
    data: {
      type: 'dynamic',
      field: 'style'
    },
    defaultOpen: false
  },
  {
    key: 'mainThemes',
    title: 'Hoofdthema',
    data: {
      type: 'dynamic',
      field: 'theme'
    },
    defaultOpen: false
  },
  {
    key: 'subThemes',
    title: "Subthema's",
    data: {
      type: 'dynamic',
      field: 'theme'
    },
    defaultOpen: false
  },
  {
    key: 'mainTechniques',
    title: 'Hoofdtechniek',
    data: {
      type: 'dynamic',
      field: 'technique'
    },
    defaultOpen: false
  },
  {
    key: 'subTechniques',
    title: 'Subtechniek',
    data: {
      type: 'dynamic',
      field: 'technique'
    },
    defaultOpen: false
  },
  {
    key: 'additionalTechniques',
    title: 'Additionele techniek',
    data: {
      type: 'dynamic',
      field: 'technique'
    },
    defaultOpen: false
  },
  {
    key: 'mainMaterials',
    title: 'Hoofdmaterial',
    data: {
      type: 'dynamic',
      field: 'material'
    },
    defaultOpen: false
  },
  {
    key: 'otherMaterials',
    title: 'Andere materialen',
    data: {
      type: 'dynamic',
      field: 'material'
    },
    defaultOpen: false
  },
  {
    key: 'paintingSurfaceMaterials',
    title: 'Materiaal beelddrager',
    data: {
      type: 'dynamic',
      field: 'material'
    },
    defaultOpen: false
  },
  // NOTE: Temporarily hidden because of inaccurate data from the API.
  // {
  //   key: 'frameTypes',
  //   title: 'Lijsttype',
  //   data: {
  //     type: 'dynamic',
  //     field: 'frameType'
  //   },
  //   defaultOpen: false
  // },
  // {
  //   key: 'frameMaterials',
  //   title: 'Lijstmateriaal',
  //   data: {
  //     type: 'dynamic',
  //     field: 'material'
  //   },
  //   defaultOpen: false
  // },
  // {
  //   key: 'frameColors',
  //   title: 'Lijstkleur',
  //   data: {
  //     type: 'dynamic',
  //     field: 'color'
  //   },
  //   defaultOpen: false
  // },
  {
    key: 'coverMaterials',
    title: 'Materiaal van cover',
    data: {
      type: 'dynamic',
      field: 'material'
    },
    defaultOpen: false
  }
]
