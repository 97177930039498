import React from 'react'
import { useMsal } from '@azure/msal-react'
import { deleteProposalLine } from '@root/services/microsoftAuthService'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material'
import { ProposalLine } from '@root/generated/graphql-request'
import { errorToastHelper } from '@root/misc/helpers'
import { useAppContext } from '@root/global/context'

interface DeleteProposalLineDialogProps {
  children: React.ReactNode
  proposalLine: ProposalLine
  onProposalLineChange: () => void
}

export const DeleteProposalLineDialog = (
  props: DeleteProposalLineDialogProps
) => {
  const { instance } = useMsal()
  const [open, setOpen] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const { errors, setErrors } = useAppContext()

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSubmit = (ev: any) => {
    setIsDeleting(true)
    ev.preventDefault()
    deleteProposalLine(props.proposalLine.id, instance)
      .then(() => {
        props.onProposalLineChange()
        setIsDeleting(false)
        closeDialog()
      })
      .catch((_err) => {
        setErrors(
          errorToastHelper(
            errors,
            'Het verwijderen van het kunstwerk uit het voorstel is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
          )
        )
      })
  }

  return (
    <>
      <div onClick={openDialog}>{props.children}</div>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle variant='h2'>
          Kunstwerk uit voorstel verwijderen
        </DialogTitle>
        <DialogContent>
          <Box width={488} display='flex' flexDirection='column' gap={2}>
            <DialogContentText>
              Dit kan niet ongedaan gemaakt worden. Eventuele opmerkingen bij
              dit kunstwerk worden ook uit het voorstel verwijdert.
            </DialogContentText>
          </Box>
          <Divider
            sx={{
              color: 'rgba(0, 0, 0, 0.08)',
              marginTop: '24px'
            }}
          />
          <DialogActions>
            <Button
              variant='contained'
              color='neutral'
              onClick={closeDialog}
              sx={{
                bgcolor: 'var(--color-neutral-200)',
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.04)'
                }
              }}
            >
              Annuleren
            </Button>
            <Button
              variant='contained'
              color='error'
              type='submit'
              disabled={isDeleting}
              sx={{
                '&.MuiButton-contained': {
                  border: '1px solid rgba(0,0,0,0.08)'
                }
              }}
            >
              Verwijderen
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
