import { IPublicClientApplication } from '@azure/msal-browser'
import {
  Proposal,
  ProposalsPageQuery,
  AccountsWithCustomerSitesQuery,
  CreateProposalInput,
  PatchProposalInput,
  DeleteProposalMutation,
  CreateProposalLineInput,
  CreateProposalLineMutation,
  PatchProposalLineInput,
  PatchProposalLineMutation,
  DeleteProposalLineMutation,
  GetDisplayablesForGridQuery,
  ProposalFilterInput,
  GetProposalsQuery,
  PaginationInput,
  DisplayableFilterInput,
  DisplayableSortInput,
  DisplayableFilterOptionsQuery,
  Displayable
} from '@generated/graphql-request'
import { getWebsiteApiUrl } from '@root/misc/environments'
import { createGraphqlRequestSdk } from '@root/misc/graphql-request-sdk'
import { hasValue, msAuthScopes } from '@root/misc/helpers'
import { PatchedRequestInit } from 'graphql-request/dist/types'

const endpoint = getWebsiteApiUrl()
const sdk = createGraphqlRequestSdk(new URL(endpoint))

/** A variant of the GraphQL client, where you can specify `fetch` options.
  * Used for example when providing an `AbortController#signal` option for the request.
  */
const sdkWithFetchOptions = (options?: PatchedRequestInit) =>
  createGraphqlRequestSdk(new URL(endpoint), options)

function raiseWhenFalsy<T> (response: T, predicate: (response: T) => any): void {
  // eslint-disable-next-line
  if (!predicate(response)) throw response;
}

/** Get the tokens needed to make API calls for the mijnBAS API.
 *  The error handling flow is as follows
 *  - Try to acquire tokens silently
 *  - Acquire tokens through a popup asking the user for credentials
 *  - Fail, and show an error toast.
 */
async function getTokens (
  instance: IPublicClientApplication
): Promise<
  { Authorization: string, 'X-MS-Graph-Access-Token': string } | undefined
  > {
  const acc = instance.getAllAccounts()
  instance.setActiveAccount(acc[0])

  const graphToken = await instance.acquireTokenSilent({
    scopes: [msAuthScopes.read]
  })

  const basToken = await instance.acquireTokenSilent({
    scopes: [msAuthScopes.api]
  })

  const result = {
    Authorization: `Bearer ${basToken.accessToken}`,
    'X-MS-Graph-Access-Token': graphToken.accessToken
  }
  return result
}

export type ListDisplayables = GetDisplayablesForGridQuery['displayables']
export type ProposalsPageData = ProposalsPageQuery

/**
 * Get Proposal by ID
 * @returns The displayable.
 */
async function getProposal (
  id: string,
  instance: IPublicClientApplication
): Promise<Proposal> {
  const headers = await getTokens(instance)

  const response = await sdk.GetProposal({ id }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response.proposal as Proposal
}

/** Fetches proposals and user profile data in one query. After logging in we can't do multiple queries because the API will create several users. */
async function getProposalsPageData (
  pagination: PaginationInput,
  instance: IPublicClientApplication
): Promise<ProposalsPageData> {
  const headers = await getTokens(instance)

  const response = await sdk.ProposalsPage({ pagination }, headers)

  raiseWhenFalsy(response, (r) => Array.isArray(r.proposals.items))
  return response
}

async function getProposals (
  input: { filter?: ProposalFilterInput, pagination?: PaginationInput },
  instance: IPublicClientApplication,
  options?: PatchedRequestInit
): Promise<GetProposalsQuery> {
  const headers = await getTokens(instance)

  const response = await sdkWithFetchOptions(options).GetProposals(input, headers)

  raiseWhenFalsy(response, (r) => Array.isArray(r.proposals.items))
  return response
}

export type ListAccountsWithCustomerSites =
  AccountsWithCustomerSitesQuery['accounts']

async function listAccountsWithCustomerSites (
  instance: IPublicClientApplication,
  searchTerm?: string
): Promise<ListAccountsWithCustomerSites> {
  const headers = await getTokens(instance)

  const response = await sdk.AccountsWithCustomerSites(
    { filter: { searchTerm } },
    headers
  )

  raiseWhenFalsy(response, (r) => Array.isArray(r.accounts))
  return response.accounts
}

async function createProposal (
  input: CreateProposalInput,
  instance: IPublicClientApplication
): Promise<Proposal> {
  const headers = await getTokens(instance)

  const response = await sdk.CreateProposal({ input }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response.createProposal as Proposal
}

async function patchProposal (
  id: string,
  input: PatchProposalInput,
  instance: IPublicClientApplication,
  options?: PatchedRequestInit
): Promise<Proposal> {
  const headers = await getTokens(instance)

  const response = await sdkWithFetchOptions(options).PatchProposal({ id, input }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response.patchProposal as Proposal
}

async function deleteProposal (
  id: string,
  instance: IPublicClientApplication
): Promise<DeleteProposalMutation> {
  const headers = await getTokens(instance)

  const response = await sdk.DeleteProposal({ id }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response
}

async function getDisplayablesForGrid (
  input: {
    filter?: DisplayableFilterInput
    sorting?: DisplayableSortInput
    pagination?: PaginationInput
  },
  instance: IPublicClientApplication,
  options?: PatchedRequestInit
): Promise<ListDisplayables> {
  const headers = await getTokens(instance)

  const response = await sdkWithFetchOptions(options).GetDisplayablesForGrid(input, headers)

  raiseWhenFalsy(response, (r) => Array.isArray(r.displayables.items))
  return response.displayables
}

async function getDisplayable (
  id: string,
  instance: IPublicClientApplication
): Promise<Displayable> {
  const headers = await getTokens(instance)

  const response = await sdk.GetDisplayable({ id }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r.displayable))
  return response.displayable as Displayable
}

async function createProposalLine (
  input: CreateProposalLineInput,
  instance: IPublicClientApplication
): Promise<CreateProposalLineMutation> {
  const headers = await getTokens(instance)

  const response = await sdk.CreateProposalLine({ input }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response
}

async function patchProposalLine (
  id: string,
  input: PatchProposalLineInput,
  instance: IPublicClientApplication,
  options?: PatchedRequestInit
): Promise<PatchProposalLineMutation> {
  const headers = await getTokens(instance)

  const response = await sdkWithFetchOptions(options).PatchProposalLine({ id, input }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response
}

async function deleteProposalLine (
  id: string,
  instance: IPublicClientApplication
): Promise<DeleteProposalLineMutation> {
  const headers = await getTokens(instance)

  const response = await sdk.DeleteProposalLine({ id }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response
}

async function listFilterOptions (
  instance: IPublicClientApplication,
  filter?: DisplayableFilterInput
): Promise<DisplayableFilterOptionsQuery['displayableFilterOptions']> {
  const headers = await getTokens(instance)

  const response = await sdk.DisplayableFilterOptions({ filter }, headers)

  raiseWhenFalsy(response, (r) => hasValue(r))
  return response.displayableFilterOptions
}

export {
  getTokens,
  getProposal,
  getProposalsPageData,
  getProposals,
  createProposal,
  patchProposal,
  deleteProposal,
  createProposalLine,
  patchProposalLine,
  deleteProposalLine,
  listAccountsWithCustomerSites,
  getDisplayablesForGrid,
  getDisplayable,
  listFilterOptions
}
