import { NavBar } from '@root/components/molecules/NavBar/Component'
import { login, tokenService } from '@services/apiService'
import React from 'react'
import { useAppContext } from '@global/context'
import { useNavigate } from 'react-router-dom'
import { hasValue } from '@misc/helpers'
import { Loader } from '@root/components/atoms/Loader/Component'
import { useMsal } from '@azure/msal-react'

interface Props {
  children?: React.ReactNode
}

export const LayoutAuth = (props: Props): JSX.Element => {
  const { userData, setUserData } = useAppContext()
  const [shouldLogin, setShouldLogin] = React.useState<boolean | null>(null)
  const [loginInProcess, setLoginInProcess] = React.useState<boolean>(true)
  const { instance } = useMsal()

  const navigation = useNavigate()

  /** If at the root of the URL, and an employee is logged in, we want to redirect to the portal.
   * However, if an employee is trying to access a customer's page, we do not want this to happen.
   */
  const fetchUserAccess = React.useCallback(async () => {
    const code = new URLSearchParams(window.location.search).get('c')
    if (!code) {
      instance.initialize().then(() => {
        if (instance.getAllAccounts().length > 0) {
          navigation('/portaal')
        }
      })
    }

    if (!hasValue(userData)) {
      if (code !== null) {
        try {
          await login(code)
        } catch (err) {
          setShouldLogin(true)
        } finally {
          setLoginInProcess(false)
        }
      } else {
        setShouldLogin(true)
        setLoginInProcess(false)
      }
    }
  }, [userData])

  React.useEffect(() => {
    fetchUserAccess()
  }, [fetchUserAccess])

  React.useEffect(() => {
    if (!loginInProcess) {
      tokenService.subscribeStatusUpdates(
        ({ loggedIn }: { loggedIn: boolean }) => {
          if (!hasValue(loggedIn) || (hasValue(loggedIn) && !loggedIn)) {
            !hasValue(shouldLogin) && setShouldLogin(true)
          } else {
            if (
              !hasValue(userData) &&
              hasValue(localStorage.getItem('userData'))
            ) {
              const items = JSON.parse(localStorage.getItem('userData') ?? '')
              if (hasValue(items)) {
                setUserData(items)
              }
            }
          }
        }
      )
    }
  }, [loginInProcess, userData, setUserData, shouldLogin])

  React.useEffect(() => {
    const status = tokenService.getStatus()
    if (
      hasValue(shouldLogin) &&
      shouldLogin &&
      !loginInProcess &&
      !status.loggedIn
    ) {
      navigation('/login')
    }
  }, [shouldLogin, loginInProcess, navigation])

  return (
    <div className='page-wrapper'>
      <NavBar />
      {loginInProcess ? <Loader /> : props.children}
    </div>
  )
}
