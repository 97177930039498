import { getSdk, Sdk } from '@generated/graphql-request'
import { GraphQLClient } from 'graphql-request'
import { PatchedRequestInit } from 'graphql-request/dist/types'

export function createGraphqlRequestSdk (url: URL, options?: PatchedRequestInit): Sdk {
  const graphqlUrl = `${url.href}`
  const client = new GraphQLClient(graphqlUrl, options)

  return getSdk(client)
}
