import { Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, OutlinedInput, Radio, RadioGroup, Slider, TextField, Typography } from '@mui/material'
import { Chevron } from '@root/components/atoms/Icons/Component'
import React from 'react'
import style from './style.module.css'
import Search from '@mui/icons-material/Search'
import { hasValue } from '@root/misc/helpers'

interface FilterObj {
  isAvailableForSale?: string
  artObjectCategory?: string[]
  numberOfUnits?: number
  mainTheme?: string[]
}

/** The callback that will be run when a filter is chosen or not. */
interface FilterViewProps {
  onChange: (filters: FilterObj) => void
  renderStatus: boolean
  toggleOpenCallback: React.Dispatch<React.SetStateAction<boolean>>
  artistSearchTerm: string
  results?: number
  historyStatus: string
}

/** The data for a filter list.
*/
interface FilterItemProps {
  /** This will be displayed as the label for the list */
  title: string
  /** The names of the filters. */
  filters: string[]
  onChange: (filters: FilterObj) => void
  objKey: string
  defaultValue?: string
}

/** The data for a filter that uses a slider. */
interface FilterSliderProps {
  /** This will be displayed as the label for the filter list */
  title: string
  min: number
  max: number
  onChange: (filters: FilterObj) => void
  objKey: string
}

/** The data for a searchfield in the list.
*/
interface FilterSearchProps {
  title: string
  onChange: (filters: FilterObj) => void
  objKey: string
  defaultSearchTerm: string
}

/** A filter list, where each filter is a radio button. */
const RadioFilters = (props: FilterItemProps): JSX.Element => {
  const [open, setOpen] = React.useState(true)

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChange({ [props.objKey]: event.target.value })
  }

  const control = (
    <Radio
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel className={style['filter-label']} onClick={() => setOpen(!open)} sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}>
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <RadioGroup onChange={changeHandler}>
          {props.filters.map(filterName =>
            <FormControlLabel key={filterName} value={filterName} control={control} label={filterName} checked={props.defaultValue === filterName} />
          )}
        </RadioGroup>
      </Collapse>
    </div>
  )
}

/** A filter list where each filter is a checkbox. */
const CheckboxFilters = (props: FilterItemProps): JSX.Element => {
  const [open, setOpen] = React.useState(true)
  const [activeFilters, setActiveFilters] = React.useState<string[]>([])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setActiveFilters([...activeFilters, event.target.value])
    } else {
      setActiveFilters(activeFilters.filter(filter => filter !== event.target.value))
    }

    props.onChange({ [props.objKey]: activeFilters })
  }

  const control = (
    <Checkbox
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel className={style['filter-label']} onClick={() => setOpen(!open)} sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}>
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {props.filters.map(filterName =>
            <FormControlLabel key={filterName} value={filterName} control={control} label={filterName} />
          )}
        </FormGroup>
      </Collapse>
    </div>
  )
}

const UnitsSlider = (props: FilterSliderProps): JSX.Element => {
  const [units, setUnits] = React.useState<number[]>([1, 100])
  const [open, setOpen] = React.useState(true)

  const handleChange = (
    _event: Event,
    newValue: number | number[]
  ): void => {
    setUnits(newValue as number[])

    props.onChange({
      [props.objKey]: units
    })
  }

  return (
    <div className={style['filter-list']}>
      <FormLabel className={style['filter-label']} onClick={() => setOpen(!open)} sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}>
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>

      <Collapse in={open} timeout='auto'>
        <FormGroup>
          <Slider
            value={units}
            onChange={handleChange}
            valueLabelDisplay='off'
            sx={{ color: 'var(--color-brand-primary-700)', marginBlockEnd: '18px' }}
          />
          <div className={style['slider-input-fields']}>
            <TextField label='Minimum' type='number' variant='outlined' value={units[0]} sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }} />
            <TextField label='Maximum' type='number' variant='outlined' value={units[1]} />
          </div>
        </FormGroup>
      </Collapse>

    </div>
  )
}

const SearchFilter = (props: FilterSearchProps): JSX.Element => {
  const [, setSearchTerm] = React.useState<string>('')

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSearchTerm(e.target.value)

    props.onChange({
      [props.objKey]: e.target.value
    })
  }

  return (
    <Box className={style['filter-list']}>
      <FormLabel className={style['filter-label']} sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}>
        <Typography variant='h4'>{props.title}</Typography>
      </FormLabel>

      <OutlinedInput
        onChange={handleChange}
        defaultValue={props.defaultSearchTerm}
        style={{ backgroundColor: '#fff', width: '100%' }}
        placeholder='Zoeken'
        size='small'
        startAdornment={
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        }
      />
    </Box>
  )
}

/** The parent component is expected to give the onchange function that will keep track of all selected filters
 *
 * Example:
 * const filterOnChange = (newFilterObject: FilterObj): void => {
 *   setFilters((oldVal: FilterObj) => ({
 *     ...oldVal,
 *     newFilterObject
 *   }))
 * }
 *
 * <Filters onChange={filterOnChange} />
 *
 * For now, because we only have search, all other filters are disabled
*/
export const Filters = (props: FilterViewProps): JSX.Element => (
  <Box className={style['filter-box']}>
    <div className={style.title}>
      <Typography variant='h2'>
        Filter kunstwerken
      </Typography>
    </div>

    <FormControl style={{ width: '100%' }}>
      <SearchFilter objKey='searchTerm' title='Kunstenaar' onChange={props.onChange} defaultSearchTerm={props.artistSearchTerm} />
      {props.renderStatus && <RadioFilters objKey='status' title='Status' filters={['Alle kunstwerken', 'Actief', 'Geretourneerd']} onChange={props.onChange} defaultValue={props.historyStatus} />}
      {/* <RadioFilters objKey='isAvailableForSale' title='Beschikbaarheid' filters={['Alle kunstwerken', 'Beschikbaar', 'Niet beschikbaar']} onChange={props.onChange} /> */}
      {/* <CheckboxFilters objKey='artObjectCategory' title='Categorie' filters={['Grafiek', 'Fotografie', 'Object']} onChange={props.onChange} /> */}
      {/* <UnitsSlider objKey='numberOfUnits' title='Eenheden' min={0} max={100} onChange={props.onChange} /> */}
      {/* <CheckboxFilters objKey='mainTheme' title='Themacategorie' filters={['Categorie 1', 'Categorie 2', 'Categorie 3']} onChange={props.onChange} /> */}
    </FormControl>

    <div className={style['filter-close-container']}>
      <Button onClick={() => props.toggleOpenCallback(false)} color='primary' variant='contained' size='large'>
        {hasValue(props.results) && (
          <>Toon {props.results} gevonden kunstwerken</>
        )}
      </Button>
    </div>
  </Box>
)
