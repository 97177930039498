import Toll from '@mui/icons-material/Toll'
import {
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {
  CloseSign,
  EuroSign,
  ImageSize,
  ShoppingCart,
  Tag
} from '@root/components/atoms/Icons/Component'
import FavoriteIcon from '@mui/icons-material/Favorite'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import useWindowSize from '@root/misc/use-window-size'
import React from 'react'
import { imageUrl } from '@misc/image-utils'
import style from './style.module.css'
import { Displayable, Proposal } from '@root/generated/graphql-request'
import {
  artSize,
  errorToastHelper,
  hasValue,
  infoValue,
  LikedState
} from '@root/misc/helpers'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { getDisplayable } from '@root/services/microsoftAuthService'
import { useMsal } from '@azure/msal-react'
import { Loader } from '@root/components/atoms/Loader/Component'
import { ProposalLineDialog } from '@root/components/atoms/ProposalLineDialog/Component'
import { DeleteProposalLineDialog } from '@root/components/atoms/DeleteProposalLineDialog/Component'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useAppContext } from '@root/global/context'

interface DisplayableDetailViewProps {
  /**
   * This contains all the data that will be displayed in the detail view.
   */
  displayable?: Displayable
  proposal?: Proposal
  open: boolean
  onChange: () => void
  onClose: () => void
  likedState: LikedState
}

interface DisplayableInfoProps {
  displayable: Displayable
  proposal?: Proposal
  onChange: () => void
  likedState: LikedState
}

const availability = (item: boolean): JSX.Element =>
  item
    ? (
      <Typography>Beschikbaar voor verkoop</Typography>
      )
    : (
      <Typography>Niet beschikbaar voor verkoop</Typography>
      )

function DisplayableInfo (props: DisplayableInfoProps): JSX.Element {
  return (
    <div className={style['art-object-info']}>
      <div>
        <Typography
          variant='body1'
          sx={{ marginBlockEnd: 0, color: 'var(--color-neutral-600)' }}
        >
          {props.displayable.referenceNumber}
        </Typography>
        <Typography variant='h1'>
          {props.displayable.mainArtist.artist}
        </Typography>
        <Typography variant='h3' sx={{ color: 'var(--color-neutral-500)' }}>
          {props.displayable.title}
        </Typography>
        <div className={style['art-extra-info']}>
          <Toll sx={{ fontSize: 16, color: 'var(--color-neutral-500)' }} />
          <Typography variant='body1'>
            {props.displayable.numberOfUnits}
          </Typography>
          <span className={style['fancy-dot']}>·</span>
          <div className={style.icon}>
            <EuroSign height='16px' width='16px' />
          </div>
          <Typography variant='body1'>
            {Math.round(props.displayable.rentalValue)},-
          </Typography>
        </div>
      </div>

      <Divider
        className={style.divider}
        sx={{ marginTop: '16px', marginBottom: '16px' }}
      />
      <div className={style.metas}>
        <div className={style.meta}>
          <div className={style.icon}>
            <Tag height='16px' width='16px' />
          </div>
          <Typography variant='body1'>
            {props.displayable.artObjectCategory?.category ?? '-'}
          </Typography>
        </div>
        <div className={style.meta}>
          <div className={style.icon}>
            <ImageSize height='16px' width='16px' />
          </div>
          <Typography variant='body1'>{artSize(props.displayable)}</Typography>
        </div>
        <div className={style.meta}>
          <div className={style.icon}>
            <ShoppingCart height='16px' width='16px' />
          </div>
          <Typography variant='body1'>
            {availability(props.displayable.isAvailableForSale)}
          </Typography>
        </div>
        <div className={style.meta}>
          <div className={style.icon}>
            <VerifiedOutlinedIcon sx={{ height: '16px', width: '16px' }} />
          </div>
          <Typography variant='body1'>{props.displayable.ownership}</Typography>
        </div>
      </div>
      {props.likedState.kind !== 'isLiked' && props.proposal != null
        ? (
          <ProposalLineDialog
            onChange={props.onChange}
            proposal={props.proposal}
            displayableId={props.displayable.id}
          >
            <Button
              variant='contained'
              sx={{ bgcolor: 'var(--color-primary-700)' }}
              startIcon={<FavoriteBorder />}
            >
              Plaats in collectievoorstel
            </Button>
          </ProposalLineDialog>
          )
        : (
          <></>
          )}
      {props.likedState.kind === 'isLiked'
        ? (
          <DeleteProposalLineDialog
            proposalLine={props.likedState.proposalLine}
            onProposalLineChange={props.onChange}
          >
            <Button
              variant='contained'
              sx={{
                '&.MuiButton-contained': {
                  bgcolor: 'var(--color-brand-quarternary-300)',
                  border: '1px solid rgba(0, 0, 0, 0.08)'
                }
              }}
              startIcon={<FavoriteIcon sx={{ fill: 'white' }} />}
            >
              Verwijder uit collectievoorstel
            </Button>
          </DeleteProposalLineDialog>
          )
        : (
          <></>
          )}
    </div>
  )
}

interface ArtImageBoxProps {
  displayable: Displayable
}

const ArtImageBox = (props: ArtImageBoxProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  return (
    <div>
      <div
        {...(hasValue(props.displayable.mainImagePath) && {
          onClick: handleOpen
        })}
        className={style['art-object-thumbnail']}
      >
        <ArtImage
          src={props.displayable.mainImagePath}
          size='medium'
          alt={props.displayable.title}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='kunstwerk volledige grootte'
      >
        <>
          <Box
            sx={{
              marginTop: '20px',
              marginLeft: '20px',
              fontSize: '20px',
              color: 'var(--color-brand-primary-100)'
            }}
          >
            <span className={style['modal-close-icon']} onClick={handleClose}>
              <CloseSign width='14px' height='14px' />
            </span>
          </Box>

          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              p: 4
            }}
          >
            <img
              src={imageUrl(props.displayable.mainImagePath as string, 'large')}
              alt={props.displayable.title}
              className={style['lightbox-image']}
            />
          </Box>
        </>
      </Modal>
    </div>
  )
}

const listInfoValue = (
  values: any[] | null | undefined,
  key: string
): string => (hasValue(values) ? values.map((v) => v[key]).join(', ') : '-')

export default function DisplayableDetailView (
  props: DisplayableDetailViewProps
): JSX.Element {
  const windowSize = useWindowSize()
  const { instance } = useMsal()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [displayable, setDisplayable] = React.useState<Displayable | undefined>(
    undefined
  )

  const { errors, setErrors } = useAppContext()

  const specifications = [
    { type: 'header', name: 'Lijst' },
    {
      type: 'info',
      name: 'Type lijst',
      value: infoValue(displayable?.frameType?.frameType, '-')
    },
    {
      type: 'info',
      name: 'Materiaal lijst',
      value: infoValue(displayable?.frameMaterial?.material, '-')
    },
    {
      type: 'info',
      name: 'Kleur lijst',
      value: infoValue(displayable?.frameColor?.color, '-')
    },
    {
      type: 'info',
      name: 'Breedte van de lijstrand',
      value: infoValue(displayable?.frameBorderWidth, '-')
    },
    {
      type: 'info',
      name: 'Materiaal van cover',
      value: infoValue(displayable?.coverMaterial?.material, '-')
    },
    { type: 'header', name: 'Kleur' },
    {
      type: 'info',
      name: 'Hoofdkleur',
      value: infoValue(displayable?.mainColor?.color, '-')
    },
    {
      type: 'info',
      name: 'Subkleuren',
      value: listInfoValue(displayable?.subColors, 'color')
    },
    {
      type: 'info',
      name: 'Kleurfamilie',
      value: infoValue(displayable?.colorFamily?.colorFamily, '-')
    },
    { type: 'header', name: 'Stijl' },
    {
      type: 'info',
      name: 'Hoofdstijl',
      value: infoValue(displayable?.mainStyle?.style, '-')
    },
    {
      type: 'info',
      name: 'Substijlen',
      value: listInfoValue(displayable?.subStyles, 'style')
    },
    { type: 'header', name: 'Thema' },
    {
      type: 'info',
      name: 'Hoofdthema',
      value: infoValue(displayable?.mainTheme?.theme, '-')
    },
    {
      type: 'info',
      name: 'Subthemas',
      value: listInfoValue(displayable?.subThemes, 'style')
    },
    { type: 'header', name: 'Techniek' },
    {
      type: 'info',
      name: 'Categorie',
      value: infoValue(displayable?.artObjectCategory?.category, '-')
    },
    {
      type: 'info',
      name: 'Hoofdtechniek',
      value: infoValue(displayable?.mainTechnique?.technique, '-')
    },
    {
      type: 'info',
      name: 'Additionele techniek',
      value: infoValue(displayable?.additionalTechnique?.technique, '-')
    },
    {
      type: 'info',
      name: 'Subtechniek',
      value: infoValue(displayable?.subTechnique?.technique, '-')
    },
    { type: 'header', name: 'Materiaal' },
    {
      type: 'info',
      name: 'Hoofdmateriaal',
      value: infoValue(displayable?.mainMaterial?.material, '-')
    },
    {
      type: 'info',
      name: 'Andere materialen',
      value: listInfoValue(displayable?.otherMaterials, 'material')
    },
    {
      type: 'info',
      name: 'Materiaal beelddrager',
      value: infoValue(displayable?.paintingSurfaceMaterial?.material, '-')
    }
  ]

  React.useEffect(() => {
    const fetchAndSetDisplayable = async () => {
      setIsLoading(true)
      try {
        const displayable = await getDisplayable(
          props.displayable!.id,
          instance
        )

        setDisplayable(displayable)
      } catch (err) {
        props.onClose()
        setErrors(
          errorToastHelper(
            errors,
            'Het ophalen van het kunstwerk is mislukt. Probeer het opnieuw of neem contact op met de beheerder.'
          )
        )
      }
      setIsLoading(false)
    }
    if (props.open) {
      fetchAndSetDisplayable()
    }
  }, [props.open])

  return (
    <>
      <Drawer
        variant='temporary'
        anchor='right'
        open={props.open}
        onClose={() => props.onClose()}
      >
        <Box
          sx={{
            width: 880,
            maxWidth: '100vw'
          }}
        >
          {isLoading && displayable === undefined
            ? (<Loader />)
            : (
              <div className={style['art-object-detail-view']}>
                <Button
                  onClick={() => {
                    props.onClose()
                  }}
                  className={style['close-button']}
                >
                  <CloseSign height='14px' width='14px' />{' '}
                  <Typography variant='button'>Sluit</Typography>
                </Button>
                {windowSize.isTabletAndAbove
                  ? (
                    <div className={style['art-object-detail-container']}>
                      {displayable != null && (
                        <DisplayableInfo
                          displayable={displayable}
                          proposal={props.proposal}
                          onChange={props.onChange}
                          likedState={props.likedState}
                        />
                      )}
                      {displayable != null && (
                        <ArtImageBox displayable={displayable} />
                      )}
                    </div>
                    )
                  : (
                    <div className={style['art-object-detail-container']}>
                      {displayable != null && (
                        <DisplayableInfo
                          displayable={displayable}
                          proposal={props.proposal}
                          onChange={props.onChange}
                          likedState={props.likedState}
                        />
                      )}
                      {displayable != null && (
                        <ArtImageBox displayable={displayable} />
                      )}
                    </div>
                    )}

                <div className={style['art-object-specifications']}>
                  <Typography variant='h3' sx={{ marginBlockEnd: '16px' }}>
                    Specificaties
                  </Typography>
                  <TableContainer>
                    <Table aria-label='specificaties tabel'>
                      {specifications.map((spec) =>
                        spec.type === 'header'
                          ? (
                            <TableHead key={spec.name}>
                              <TableRow
                                sx={{
                                  backgroundColor: 'var(--color-brand-primary-100)'
                                }}
                              >
                                <TableCell>{spec.name}</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            )
                          : (
                            <TableBody>
                              <TableRow key={spec.name}>
                                <TableCell component='th'>{spec.name}</TableCell>
                                <TableCell>{spec.value}</TableCell>
                              </TableRow>
                            </TableBody>
                            )
                      )}
                    </Table>
                  </TableContainer>
                  <Link
                    href={displayable?.backOfficeLink ?? ''}
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{ width: '100%' }}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      endIcon={<ArrowForwardIcon />}
                      sx={{
                        '&.MuiButton-contained': {
                          bgcolor: 'var(--color-brand-primary-100)',
                          color: 'var(--color-brand-primary-700)',
                          border: '1px solid rgba(0, 0, 0, 0.08)',
                          marginY: '40px',
                          minWidth: '100%'
                        },
                        '&.MuiButton-contained:hover': {
                          bgcolor: 'var(--color-brand-primary-200)'
                        }
                      }}
                    >
                      Open in Zoho Art
                    </Button>
                  </Link>
                </div>
              </div>
              )}
        </Box>
      </Drawer>
    </>
  )
}
