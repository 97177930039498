import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  Slider,
  TextField,
  Typography
} from '@mui/material'
import { Chevron } from '@root/components/atoms/Icons/Component'
import React from 'react'
import style from './style.module.css'
import { hasValue } from '@root/misc/helpers'
import { listFilterOptions } from '@root/services/microsoftAuthService'
import { useMsal } from '@azure/msal-react'
import { Loader } from '@root/components/atoms/Loader/Component'
import {
  Artist,
  ArtworkOrientation,
  DisplayableAvailability,
  DisplayableFilterOptions
} from '@root/generated/graphql-request'
import { displayableFilters } from './filterData'
import { isArray } from 'lodash'

function usePrevious (value: any) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

interface FilterViewProps {
  /** The callback that will be run when a filter is chosen or not. */
  onFilterChange: (filters: any) => void
  toggleOpenCallback: React.Dispatch<React.SetStateAction<boolean>>
  results?: number
}

interface AvailabilityListProps {
  /** This will be displayed as the label for the list */
  title: string
  onChange: (filters: DisplayableAvailability[]) => void
  objKey: string
}

interface OrientationListProps {
  /** This will be displayed as the label for the list */
  title: string
  options: string[]
  onChange: (filters: string[]) => void
  defaultOpen: boolean
}

/** The data for a filter list.
 */
interface FilterCheckboxProps {
  /** This will be displayed as the label for the checkbox list */
  title: string
  /** The names of the filters. */
  filters: Array<{ id: string, name: string } | null>
  onChange: (filters: { key: string, value?: string[] }) => void
  objKey: string
  defaultValue?: string
  defaultOpen: boolean
}

/** The data for an ownership filter list. */
interface OwnershipFilterProps {
  /** This will be displayed as the label for the checkbox list */
  title: string
  /** The names of the filters. */
  options: string[]
  onChange: (filters: string[] | null) => void
  defaultOpen: boolean
}

/** Data for a list where only a single option can be active. */
interface RadioFilterProps {
  /** This will be displayabled as the label for the radio group list */
  title: string
  /** Contains each option: labels and value. */
  onChange: (filters: { key: string, value: null | boolean }) => void
  /* Name of the filter when passing it to `DisplayableFilterInput` */
  objKey: string
  options: Array<{ label: string, value: null | boolean }>
  defaultOpen: boolean
  defaultValue: boolean | null
}

/** The data for a filter that uses a slider. */
interface FilterSliderProps {
  /** This will be displayed as the label for the filter list */
  title: string
  min: number
  max: number
  steps: number
  onChange: (filters: { key: string, value: number[] }) => void
  objKey: string
  defaultOpen: boolean
}

/** The data for the artist search dialog.
 */
interface FilterSearchProps {
  title: string
  onChange: (filters: string[]) => void
  objKey: string
  artists: DisplayableFilterOptions['mainArtists']
}

const getFilterValues = (
  key: string,
  field: string,
  options: DisplayableFilterOptions
): Array<{ id: string, name: string } | null> => {
  const filter = options[key as keyof DisplayableFilterOptions]
  if (filter && isArray(filter)) {
    return filter.map((f: any) =>
      f?.[field] ? { id: f.id, name: f[field] } : null
    )
  } else return []
}

const AvailabilityFilters = (props: AvailabilityListProps): JSX.Element => {
  const [open, setOpen] = React.useState(true)
  const [selected, setSelected] = React.useState<string>('Alle kunstwerken')
  const filters = ['Alle kunstwerken', 'Beschikbaar', 'Niet beschikbaar']

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelected(event.target.value)
  }

  React.useEffect(() => {
    switch (selected) {
      case 'Beschikbaar':
        props.onChange([DisplayableAvailability.Available])
        break
      case 'Niet beschikbaar':
        props.onChange([DisplayableAvailability.Unavailable])
        break
      default:
        props.onChange([
          DisplayableAvailability.Available,
          DisplayableAvailability.Unavailable
        ])
        break
    }
  }, [selected])

  const control = (
    <Radio
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {filters.map((filterName) => (
            <FormControlLabel
              key={filterName}
              value={filterName}
              control={control}
              label={filterName}
              checked={selected.includes(filterName)}
            />
          ))}
        </FormGroup>
      </Collapse>
    </div>
  )
}

/** A more generic single select component. The value of an option however can only be:
  *   - null
  *   - true
  *   - false
  * And the options themselves can be ordered according to a spec defined in `filterData.ts`
  */
const RadioFilter = (props: RadioFilterProps): JSX.Element => {
  const [open, setOpen] = React.useState(true)
  const [selected, setSelected] = React.useState<boolean | null>(
    props.defaultValue
  )

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === 'true') {
      setSelected(true)
    } else if (event.target.value === 'false') {
      setSelected(false)
    } else {
      setSelected(null)
    }
  }

  React.useEffect(() => {
    props.onChange({ key: props.objKey, value: selected })
  }, [selected])

  const control = (
    <Radio
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {props.options.map((f) => (
            <FormControlLabel
              key={f.label}
              value={f.value}
              control={control}
              label={f.label}
              checked={selected === f.value}
            />
          ))}
        </FormGroup>
      </Collapse>
    </div>
  )
}
/** A hardcoded component for the 'Eigendomschap' filter.
 * Same vibes as the `CheckboxFilter`, but the `ownership` is slightly different.
 * Refactoring this to use `CheckboxFilter` would mean an API change(the fields need to be structurally the same).
 */
const OwnershipFilter = (props: OwnershipFilterProps): JSX.Element => {
  const [open, setOpen] = React.useState(props.defaultOpen)
  const [selected, setSelected] = React.useState<string[]>([])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setSelected([...(selected ?? []), event.target.value])
    } else {
      setSelected(selected.filter((filter) => filter !== event.target.value))
    }
  }

  React.useEffect(() => {
    if (selected.length === 0) {
      props.onChange(null)
    } else {
      props.onChange(selected)
    }
  }, [selected])

  const control = (
    <Checkbox
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {props.options.map((filterName) => (
            <FormControlLabel
              key={filterName}
              value={filterName}
              control={control}
              label={filterName}
              checked={selected.includes(filterName)}
            />
          ))}
        </FormGroup>
      </Collapse>
    </div>
  )
}

const OrientationList = (props: OrientationListProps): JSX.Element => {
  const [open, setOpen] = React.useState(props.defaultOpen)
  const [activeFilters, setActiveFilters] = React.useState<string[]>([])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setActiveFilters([...activeFilters, event.target.value])
    } else {
      setActiveFilters(
        activeFilters.filter((filter) => filter !== event.target.value)
      )
    }
  }

  React.useEffect(() => {
    props.onChange(activeFilters)
  }, [activeFilters])

  const control = (
    <Checkbox
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {props.options.map((filterName) => (
            <FormControlLabel
              key={filterName}
              value={filterName}
              control={control}
              label={filterName}
              checked={activeFilters.includes(filterName)}
            />
          ))}
        </FormGroup>
      </Collapse>
    </div>
  )
}

/** A filter list where each filter is a checkbox. */
const CheckboxFilters = (props: FilterCheckboxProps): JSX.Element => {
  // Decides if the filter is collapsed
  const [open, setOpen] = React.useState(props.defaultOpen)
  const [activeFilters, setActiveFilters] = React.useState<string[]>([])
  // Decides if a list of > 6 items should be shown
  const [showAll, setShowAll] = React.useState(false)

  React.useEffect(() => {
    // We have to send `undefined` if there are no filters active, because the API does not accept an empty array
    if (activeFilters.length === 0) {
      props.onChange({ key: props.objKey, value: undefined })
    } else {
      props.onChange({ key: props.objKey, value: activeFilters })
    }
  }, [activeFilters])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setActiveFilters([...(activeFilters ?? []), event.target.value])
    } else {
      setActiveFilters(
        activeFilters.filter((filter) => filter !== event.target.value)
      )
    }
  }

  const control = (
    <Checkbox
      sx={{
        paddingBottom: '4px',
        paddingTop: '4px',
        '&.Mui-checked': {
          color: 'var(--color-brand-primary-700)'
        }
      }}
    />
  )

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <FormGroup onChange={changeHandler}>
          {props.filters.map((filter, idx) =>
            idx < 5 || showAll
              ? (
                <FormControlLabel
                  key={filter != null ? filter.id : '(leeg)'}
                  value={filter != null ? filter.id : '(leeg)'}
                  control={control}
                  label={filter != null ? filter.name : '(leeg)'}
                  checked={(activeFilters ?? []).includes(
                    filter != null ? filter.id : '(leeg)'
                  )}
                />
                )
              : (
                <></>
                )
          )}
          {props.filters.length >= 6 && (
            <Button
              onClick={() => setShowAll((prev) => !prev)}
              startIcon={
                <Chevron flipped={!showAll} height='24px' width='24px' />
              }
              sx={{
                marginTop: '5px',
                paddingLeft: '2px',
                justifyContent: 'start'
              }}
            >
              <Typography variant='body1'>
                {showAll ? 'Minder' : 'Meer'}
              </Typography>
            </Button>
          )}
        </FormGroup>
      </Collapse>
    </div>
  )
}

const UnitsSlider = (props: FilterSliderProps): JSX.Element => {
  const [units, setUnits] = React.useState<number[]>([props.min, props.max])
  const [open, setOpen] = React.useState(props.defaultOpen)

  const handleChange = (_event: Event, newValue: number | number[]): void => {
    setUnits(newValue as number[])
  }

  React.useEffect(() => {
    props.onChange({
      key: props.objKey,
      value: units
    })
  }, [units])

  return (
    <div className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>

      <Collapse in={open} timeout='auto'>
        <FormGroup>
          <Slider
            value={units}
            min={props.min}
            max={props.max}
            step={props.steps}
            onChange={handleChange}
            valueLabelDisplay='off'
            sx={{
              color: 'var(--color-brand-primary-700)',
              marginBlockEnd: '18px'
            }}
          />
          <div className={style['slider-input-fields']}>
            <TextField
              label='Minimum'
              type='number'
              inputProps={{
                step: props.steps
              }}
              variant='outlined'
              value={units[0]}
              sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
              onChange={(ev) => {
                // We have to actually do this validation, float checking,
                // because the API does not allow floats in some filters.
                const isFloat = props.steps % 1 !== 0
                const result = isFloat
                  ? parseFloat(ev.target.value)
                  : parseInt(ev.target.value)
                setUnits([result ?? units[0], units[1]])
              }}
            />
            <TextField
              label='Maximum'
              type='number'
              inputProps={{
                step: props.steps
              }}
              variant='outlined'
              value={units[1]}
              sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
              onChange={(ev) => {
                const isFloat = props.steps % 1 !== 0
                const result = isFloat
                  ? parseFloat(ev.target.value)
                  : parseInt(ev.target.value)
                setUnits([units[0], result ?? units[1]])
              }}
            />
          </div>
        </FormGroup>
      </Collapse>
    </div>
  )
}

const ArtistSearch = (props: FilterSearchProps): JSX.Element => {
  const [, setSearchTerm] = React.useState<string>('')
  const [selectedArtists, setSelectedArtists] = React.useState<Artist[]>([])
  const [open, setOpen] = React.useState(true)

  const filterOptions = createFilterOptions({
    limit: 25
  })

  const handleChange = (newArtists: Artist[]) => {
    setSelectedArtists(newArtists)
    props.onChange(newArtists.map((a) => a.id))
  }

  return (
    <Box className={style['filter-list']}>
      <FormLabel
        className={style['filter-label']}
        onClick={() => setOpen(!open)}
        sx={{ '&.Mui-focused': { color: 'var(--color-neutral-900)' } }}
      >
        <Typography variant='h4'>{props.title}</Typography>
        <Chevron width='16px' height='16px' flipped={open} />
      </FormLabel>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={style['artist-search-container']}>
          <Autocomplete
            fullWidth
            multiple
            id='artist-search'
            options={props.artists}
            getOptionLabel={(a: any) => a?.artist!}
            value={selectedArtists}
            filterOptions={filterOptions}
            onChange={(_ev, newVal) => {
              handleChange(newVal as Artist[])
            }}
            isOptionEqualToValue={(option, value) =>
              (option as Artist).id === (value as Artist).id}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Zoek' />
            )}
          />
        </div>
      </Collapse>
    </Box>
  )
}

/** The parent component is expected to give the onchange function that will keep track of all selected filters
 *
 * Example:
 * const filterOnChange = (newFilterObject: FilterObj): void => {
 *   setFilters((oldVal: FilterObj) => ({
 *     ...oldVal,
 *     newFilterObject
 *   }))
 * }
 *
 * <Filters onChange={filterOnChange} />
 *
 */
export const Filters = (props: FilterViewProps): JSX.Element => {
  const { instance } = useMsal()
  const [filterOptions, setFilterOptions] =
    React.useState<DisplayableFilterOptions | null>(null)
  /** The type of this is an object with keys being `keyof DisplayableFilterOptions` with a `string[]` as value */
  const [selectedDynamicFiltersState, setSelectedDynamicFiltersState] =
    React.useState<any>({
      additionalTechniques: undefined,
      artObjectCategories: undefined,
      colorFamilies: undefined,
      coverMaterials: undefined,
      frameColors: undefined,
      frameMaterials: undefined,
      frameTypes: undefined,
      mainColors: undefined,
      mainMaterials: undefined,
      mainStyles: undefined,
      mainTechniques: undefined,
      mainThemeCategories: undefined,
      mainThemes: undefined,
      objectTypes: undefined,
      otherMaterials: undefined,
      paintingSurfaceMaterials: undefined,
      subColors: undefined,
      subStyles: undefined,
      subTechniques: undefined,
      subThemes: undefined,
      hasImage: true,
      hideOnWebsiteBtb: false
    })

  const [selectedUnitsFilters, setSelectedUnitsFilters] = React.useState<any>({
    numberOfUnits: {
      min: 0,
      max: 20
    },
    height: {
      min: 0,
      max: 500
    },
    width: {
      min: 0,
      max: 500
    },
    pictureHeight: {
      min: 0,
      max: 500
    },
    pictureWidth: {
      min: 0,
      max: 500
    },
    depth: {
      min: 0,
      max: 300
    },
    rentalValue: {
      min: 0,
      max: 20000
    }
  })
  const [selectedArtists, setSelectedArtist] = React.useState<{
    mainArtists: string[]
  } | null>(null)
  const [selectedAvailability, setSelectedAvailablity] = React.useState<{
    availabilities: DisplayableAvailability[]
  }>({
    availabilities: [
      DisplayableAvailability.Available,
      DisplayableAvailability.Unavailable
    ]
  })
  const [selectedOrientation, setSelectedOrientation] = React.useState<{
    orientation: ArtworkOrientation[]
  } | null>(null)
  const [selectedOwnerships, setSelectedOwnerships] = React.useState<{
    ownerships: string[] | null
  }>({ ownerships: null })
  const [loading, setLoading] = React.useState<boolean>(true)
  const prevFilters = usePrevious({
    ...selectedDynamicFiltersState,
    ...selectedAvailability,
    ...selectedOrientation,
    ...selectedUnitsFilters,
    ...(selectedOwnerships.ownerships != null ? selectedOwnerships : null),
    ...(selectedArtists ?? null)
  })

  const mostRecentFilterRequest = React.useRef<Promise<any> | null>(null)

  /** Whenever a filter changes, we need to fetch the next set of filter options and update the filter bar.
   * Because of multiple useEffects and the initial fetch(and just time constaints),
   * we do a comparison to make sure we only call the API when needed.
   */
  React.useEffect(() => {
    const filters = {
      ...selectedDynamicFiltersState,
      ...selectedAvailability,
      ...selectedOrientation,
      ...selectedUnitsFilters,
      ...(selectedOwnerships.ownerships != null ? selectedOwnerships : null),
      ...(selectedArtists ?? null)
    }

    const previous = JSON.stringify(prevFilters)
    const current = JSON.stringify(filters)

    const debounceFn = setTimeout(() => {
      const previous = JSON.stringify(prevFilters)
      const current = JSON.stringify(filters)

      if (previous !== current) {
        props.onFilterChange(filters)
        const currentFilterRequest = listFilterOptions(instance, filters).then(
          (resp) => resp
        )

        mostRecentFilterRequest.current = currentFilterRequest

        currentFilterRequest.then((resp) => {
          if (currentFilterRequest === mostRecentFilterRequest.current) {
            setFilterOptions(resp)
            setLoading(false)
          }
        })
      }
    }, 250)

    if (previous !== current) {
      props.onFilterChange(filters)
      return () => clearTimeout(debounceFn)
    } else return
  }, [
    selectedDynamicFiltersState,
    selectedAvailability,
    selectedOwnerships,
    selectedOrientation,
    selectedArtists,
    selectedUnitsFilters
  ])

  const handleDynamicFiltersChange = (newFilter: any) => {
    let filtersWithNull: Array<string | null> | undefined
    if (newFilter.value) {
      filtersWithNull = newFilter.value.map((v: string | null) => {
        if (v === '(leeg)') {
          return null
        } else return v
      })
    }
    setSelectedDynamicFiltersState((prevState: any) => ({
      ...prevState,
      [newFilter.key]: filtersWithNull
    }))
  }

  // TODO: figure out how to type this correctly
  const handleUnitFiltersChange = (newFilter: any) => {
    setSelectedUnitsFilters((prevState: any) => ({
      ...prevState,
      [newFilter.key]: { min: newFilter.value[0], max: newFilter.value[1] }
    }))
  }

  const handleArtistSelect = (artists: string[]) => {
    if (artists.length === 0) {
      setSelectedArtist(null)
    } else setSelectedArtist({ mainArtists: artists })
  }

  const handleAvailabilitySelect = (status: DisplayableAvailability[]) => {
    setSelectedAvailablity({ availabilities: status })
  }

  const handleOwnership = (ownerships: string[] | null) => {
    setSelectedOwnerships({ ownerships })
  }

  /** Handles filters where it is a single select with options: `null`, `true` or `false`. */
  const handleBooleanFilterChange = (newFilter: {
    key: string
    value: boolean | null
  }) => {
    setSelectedDynamicFiltersState((prevState: any) => ({
      ...prevState,
      [newFilter.key]: newFilter.value
    }))
  }

  const handleOrientationChange = (filters: string[]): void => {
    const mapToOrientation = (filter: string): ArtworkOrientation => {
      switch (filter) {
        case 'Driedimensionaal':
          return ArtworkOrientation.ThreeDimensional
        case 'Portret':
          return ArtworkOrientation.Portrait
        case 'Landschap':
          return ArtworkOrientation.Landscape
        case 'Vierkant':
          return ArtworkOrientation.Square
        default:
          throw new Error('invalid ArtworkOrientation found')
      }
    }

    if (filters.length === 0) {
      setSelectedOrientation(null)
    } else {
      setSelectedOrientation({
        orientation: filters.map((o) => mapToOrientation(o))
      })
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Box className={style['filter-box']}>
      <div className={style.title}>
        <Typography variant='h2'>Filter kunstwerken</Typography>
      </div>

      <FormControl style={{ width: '100%' }}>
        <AvailabilityFilters
          objKey='status'
          title='Beschikbaarheid'
          onChange={handleAvailabilitySelect}
        />
        {displayableFilters.map((f) => {
          if (f.data.type === 'dynamic') {
            return (
              <CheckboxFilters
                objKey={f.key}
                title={f.title}
                filters={
                  filterOptions != null
                    ? getFilterValues(f.key, f.data.field, filterOptions)
                    : []
                }
                onChange={handleDynamicFiltersChange}
                key={f.key}
                defaultOpen={f.defaultOpen}
              />
            )
          }
          if (f.data.type === 'slider') {
            return (
              <UnitsSlider
                objKey={f.key}
                title={f.title}
                min={f.data.min}
                max={f.data.max}
                steps={f.data.steps}
                onChange={handleUnitFiltersChange}
                defaultOpen={f.defaultOpen}
                key={f.key}
              />
            )
          }
          if (f.data.type === 'orientation') {
            return (
              <OrientationList
                defaultOpen={f.defaultOpen}
                title={f.title}
                options={f.data.options}
                key={f.key}
                onChange={handleOrientationChange}
              />
            )
          }

          if (f.data.type === 'ownership') {
            return (
              <OwnershipFilter
                title={f.title}
                options={(filterOptions?.ownerships as string[]) ?? []}
                defaultOpen={f.defaultOpen}
                onChange={handleOwnership}
                key={f.key}
              />
            )
          }

          if (f.data.type === 'boolean') {
            return (
              <RadioFilter
                title={f.title}
                options={f.data.options}
                defaultOpen={f.defaultOpen}
                onChange={handleBooleanFilterChange}
                objKey={f.key}
                defaultValue={f.data.defaultValue}
              />
            )
          }

          if (f.data.type === 'searchArtists') {
            return (
              <ArtistSearch
                objKey={f.key}
                title='Kunstenaar'
                onChange={handleArtistSelect}
                artists={filterOptions?.mainArtists ?? []}
              />
            )
          }
        })}
      </FormControl>

      <div className={style['filter-close-container']}>
        <Button
          onClick={() => props.toggleOpenCallback(false)}
          color='primary'
          variant='contained'
          size='large'
        >
          {hasValue(props.results) && (
            <>Toon {props.results} gevonden kunstwerken</>
          )}
        </Button>
      </div>
    </Box>
  )
}
