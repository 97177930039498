import { useMsal } from '@azure/msal-react'
import { redirect } from 'react-router-dom'

const mijnBasToken = {
  scopes: ['api://mijn.businessartservice.nl/all']
}

const msGraphToken = {
  scopes: ['user.read']
}

export const RedirectPage = (): JSX.Element => {
  const { instance } = useMsal()

  instance.initialize().then((_) => {
    instance.handleRedirectPromise().then((resp) => {
      if (resp === null) {
        console.error('Authentication failed')
      } else {
        return redirect('/portaal')
      }
    })
  })

  return <>Hello</>
}
