import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './style/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'

import { PublicClientApplication } from '@azure/msal-browser'
import { getMSAuthority, getMSClientId } from './misc/environments'

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: getMSClientId(),
    authority: getMSAuthority()
  },
  cache: {
    cacheLocation: 'localStorage'
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
