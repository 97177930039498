import React from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import MoreVert from '@mui/icons-material/MoreVert'

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography
} from '@mui/material'
import { Proposal } from '@root/generated/graphql-request'
import { ProposalDialog } from '../ProposalDialog/Component'
import { DeleteProposalDialog } from '../DeleteProposalDialog/Component'

interface Props {
  proposal: Proposal
  iconColor?: 'rgba(148, 148, 148, 1)' | 'rgba(161, 187, 232, 1)'
  onEditSuccess: (proposal: Proposal) => void
  onDelete: () => void
}

export const ProposalQuickMenu = ({
  proposal,
  onEditSuccess,
  onDelete,
  iconColor = 'rgba(148, 148, 148, 1)'
}: Props) => {
  const buttonId = 'proposal-edit-button-' + proposal.id
  const anchorId = 'proposal-edit-menu-' + proposal.id
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={buttonId}
        aria-controls={open ? anchorId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert sx={{ fill: iconColor }} />
      </IconButton>
      <Menu open={open} id={anchorId} onClose={handleClose} anchorEl={anchorEl}>
        <MenuList>
          <ProposalDialog
            proposal={proposal}
            onSuccess={(p) => {
              onEditSuccess(p)
              handleClose()
            }}
          >
            <MenuItem
              sx={{
                height: '48px',
                gap: '24px',
                '&:hover': {
                  backgroundColor: 'rgba(245, 247, 251, 1)'
                }
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '24px'
                }}
              >
                <EditOutlinedIcon width='24px' height='24px' />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='body1'>Pas voorstel aan</Typography>
              </ListItemText>
            </MenuItem>
          </ProposalDialog>
          <DeleteProposalDialog proposal={proposal} onDelete={onDelete}>
            <MenuItem
              sx={{
                color: 'rgba(183, 21, 56, 1)',
                height: '48px',
                gap: '24px',
                '&:hover': {
                  backgroundColor: 'rgba(245, 247, 251, 1)'
                }
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '24px'
                }}
              >
                <DeleteForeverRoundedIcon
                  sx={{ color: 'rgba(183, 21, 56, 1)' }}
                  width='24px'
                  height='24px'
                />
              </ListItemIcon>
              <ListItemText>Voorstel verwijderen</ListItemText>
            </MenuItem>
          </DeleteProposalDialog>
        </MenuList>
      </Menu>
    </div>
  )
}
